<template>
    <div>
        <v-dialog v-model="dialog" v-if="dialog" scrollable max-width="98%" persistent :transition="false">
          <v-card>
            <v-card-title>Lembar Timbang</v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle class="pt-2 font-weight-bold">
              Nomor Produksi: {{production.prod_no}} <br>
              Tanggal Produksi: {{production.production_date | moment}}
            </v-card-subtitle>
            <v-card style="height: 400px;" outlined class="mx-5 mt-0 mb-5">
              <v-simple-table fixed-header height="350px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Kode Bahan</th>
                      <th class="text-left">Nama Bahan</th>
                      <th class="text-left">Planning Timbang (kg)</th>
                      <th class="text-left">Aktual Timbang (kg)</th>
                      <th class="text-left">Daging (kg)</th>
                      <th class="text-left">Potong</th>
                      <th class="text-left">Kulit (kg)</th>
                      <th class="text-left">Kulit</th>
                      <th class="text-left">Busuk (kg)</th>
                      <th class="text-left">Selisih (kg)</th>
                      <th class="text-left">Planning Juice (ml)</th>
                      <th class="text-left">Aktual Juice (ml)</th>
                      <th class="text-left">Yield1</th>
                      <th class="text-left">Yield2</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj, idx) in production.details" :key="idx">
                      <td class="text-left text-no-wrap">{{obj.material_code}}</td>
                      <td class="text-left text-no-wrap">{{obj.material_name}}</td>
                      <td class="text-left">{{Number(obj.qty_request)}}</td>
                      <td class="text-left">
                        <v-text-field type="number" hide-spin-buttons class="compact-form" v-model="obj.weighing_actual" outlined dense hide-details="auto" @wheel="$event.target.blur()" style="min-width:70px;"></v-text-field>
                      </td>
                      <td class="text-left">
                        <v-text-field type="number" hide-spin-buttons class="compact-form" v-model="obj.flesh_weight" outlined dense hide-details="auto" :disabled="!obj.complete_fill" @wheel="$event.target.blur()" style="min-width:70px;"></v-text-field>
                      </td>
                      <td class="text-left">{{Math.round((Number(obj.flesh_weight)/Number(obj.weighing_actual))*100)}}%</td>
                      <td class="text-left">
                        <v-text-field type="number" hide-spin-buttons class="compact-form" v-model="obj.skin_weight" outlined dense hide-details="auto" :disabled="!obj.complete_fill" @wheel="$event.target.blur()" style="min-width:70px;"></v-text-field>
                      </td>
                      <td class="text-left">{{Math.round((Number(obj.skin_weight)/Number(obj.weighing_actual))*100)}}%</td>
                      <td class="text-left">
                        <v-text-field type="number" hide-spin-buttons class="compact-form" v-model="obj.rotten_weight" outlined dense hide-details="auto" :disabled="!obj.complete_fill" @wheel="$event.target.blur()" style="min-width:70px;"></v-text-field>
                      </td>
                      <td class="text-left">{{((Number(obj.skin_weight)+Number(obj.flesh_weight)+Number(obj.rotten_weight))-Number(obj.weighing_actual)).toFixed(2)}}</td>
                      <td class="text-left">{{Math.round(Number(obj.planning_juice)) | numbers}}</td>
                      <td class="text-left">
                        <v-text-field type="number" hide-spin-buttons class="compact-form" v-model="obj.juice_weight" outlined dense hide-details="auto" @wheel="$event.target.blur()" style="min-width:70px;"></v-text-field>
                      </td>
                      <td class="text-left">{{Math.round((obj.flesh_weight/obj.weighing_actual)*100)}}%</td>
                      <td class="text-left">{{Math.round(((obj.juice_weight/1000)/obj.flesh_weight)*100)}}%</td>
                    </tr>

                  </tbody>
                </template>
              </v-simple-table>     
            </v-card>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save {{(coldPressed)?'& End Cold Pressed':''}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data: () => ({
      dialog: false,
      production: null,
      coldPressed: false,
      onProgress: false,
      isEdit: false,
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }
    },

    methods: {
        async openDialog(obj) {
            this.dialog = true
            if (obj.status == '') this.isEdit = true
            else this.isEdit = false

            obj.details.forEach(element => {
              this.$set(element, 'material_name', element.description)
              this.$set(element, 'qty_request', (element.berat_buah_kg || 0))
              // this.$set(element, 'weighing_actual', (element.berat_buah_kg || 0))
              this.$set(element, 'weighing_actual', null)
              this.$set(element, 'flesh_weight', null)
              this.$set(element, 'skin_weight', null)
              this.$set(element, 'rotten_weight', null)
              this.$set(element, 'planning_juice', element.jml_resep_ml)
              this.$set(element, 'juice_weight', null)
            });
            this.production = JSON.parse(JSON.stringify(obj))
            this.onProgress = true
            console.log(this.production)
            this.onProgress = false
        },
        async openEditDialog(obj) {
          this.onProgress = true
          this.dialog = true
          this.isEdit = true
          // this.production = JSON.parse(JSON.stringify(obj))
          console.log(obj.details)
          // this.production.details.sort((a,b) => a.material_name.localeCompare(b.material_name));
          obj.details.forEach(element => {
            this.$set(element, 'material_name', element.description)
            this.$set(element, 'qty_request', (element.berat_buah_kg || 0))
            // this.$set(element, 'weighing_actual', (element.weighing_actual || element.berat_buah_kg))
            this.$set(element, 'weighing_actual', element.weighing_actual)
            this.$set(element, 'flesh_weight', element.flesh_weight)
            this.$set(element, 'skin_weight', element.skin_weight)
            this.$set(element, 'rotten_weight', element.rotten_weight)
            this.$set(element, 'planning_juice', element.jml_resep_ml)
            this.$set(element, 'juice_weight', element.juice_weight)
          });

          this.production = JSON.parse(JSON.stringify(obj))
          this.onProgress = false
        },
        async save() {
          this.onProgress = true
          this.production.status = 'PROSES'
          this.clean(this.production)
          let complete = true
          this.production.details.forEach(obj => {
              if(obj.weighing_actual==='' || obj.juice_weight===''){
                complete = false
                console.log(obj.weighing_actual, obj.juice_weight, '1false')
              }
              if (obj.complete_fill) {
                if (!obj.flesh_weight || !obj.skin_weight || !obj.rotten_weight || obj.flesh_weight==='' || obj.skin_weight==='' || obj.rotten_weight===''){
                  complete = false
                  console.log('2false')
                }
              }
              this.clean(obj)
              console.log(obj, complete)
          })

          if (complete) this.production.coldpress_end = Date.now()
          else  this.production.coldpress_end = ''

          let docs = {}
          let order = {
            status: 1
          };
          await this.$store.dispatch('order/putObj', {apidomain:'orders?fields[prod_no]='+this.production.prod_no, payload:order})

          try {
            docs = await this.$store.dispatch('production/postObj', this.production)
          }
          catch (error) {
            console.log(error)
            docs = await this.$store.dispatch('production/putObj', this.production)
          }

          if (docs.status == 200 || docs.status == 201) {
            this.onProgress = false
            this.dialog = false
            this.$emit('refresh')
          }
        },
        clean(obj) {
            for (var propName in obj) {
                // if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
                }
            }
            return obj
        }



    }    
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */
</style>