<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" small depressed @click="initialize">Refresh</v-btn>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn v-if="item.status!='FINISHPROD' || profile.role_code=='SUP'" color="green" small depressed @click="printBOM(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">BOM</v-btn>
                <v-btn v-if="item.status!='FINISHPROD' || profile.role_code=='SUP'" color="green" small depressed @click="printBOM2(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">BOM2</v-btn>
                <v-btn v-if="item.status!='FINISHPROD' || profile.role_code=='SUP'" color="green" small depressed @click="printBOM3(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">BOM3</v-btn>
                <v-btn v-if="item.status!='FINISHPROD' || profile.role_code=='SUP'" color="green" small depressed @click="printResep(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">Resep</v-btn>
                <v-btn v-if="!item.status" :disabled="item.ostatus==0" color="green" small depressed @click="inputLembarTimbang(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">Lembar Timbang</v-btn>
                <v-btn v-if="item.status=='PROSES' || (profile.role_code=='SUP' && item.status)" color="green" small depressed @click="editHasil(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">Lembar Timbang</v-btn>
                <v-btn v-if="item.status!='FINISHPROD' || profile.role_code=='SUP'" :disabled="item.ostatus==0" color="green" small depressed @click="finishedGoods(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">Finished Goods</v-btn>
                <v-btn v-if="item.coldpress_end && item.filling_end && item.status!='FINISHPROD'" color="green" small depressed @click="finishProd(item)" class="mr-2 my-1 white--text" style="text-transform: none; letter-spacing: 0px">Finish</v-btn>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.production_date`]="{ item }">
              {{item.production_date | moment}}
            </template>            
            <template v-slot:[`item.status`]="{ item }">
              {{(item.status)? getStatusName(item.status) : 'Not Started' }}
            </template>
            <template v-slot:[`item.lembar_timbang`]="{ item }">
              {{(item.coldpress_end)? 'Completed' : 'Incomplete' }}
            </template>            
            <template v-slot:[`item.finished_goods`]="{ item }">
              {{(item.filling_end)? 'Completed' : 'Incomplete' }}
            </template>

        </v-data-table>

        <lembar-timbang ref="refLembarTimbang" @refresh="initialize"></lembar-timbang>
        <PrintBOM ref="printBom"></PrintBOM>
        <PrintBOM2 ref="printBom2"></PrintBOM2>
        <PrintBOM3 ref="printBom3"></PrintBOM3>
        <print-resep ref="printResep"></print-resep>

        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog v-model="dialogFinish" max-width="500px">
          <v-card>
              <v-card-title class="justify-center">Peringatan!</v-card-title>
              <v-card-text class="body-1 text-center">Proses ini akan meng-update stok akhir finished goods. Dan anda tidak bisa lagi merubah data produksi ini setelahnya. Anda yakin?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelFinish">No</v-btn>
              <v-btn color="blue darken-1" text @click="confirmFinish">Yes</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirmPrintResep" max-width="500px">
          <v-card>
              <v-card-title class="justify-center">Peringatan!</v-card-title>
              <v-card-text class="body-1 text-center">Setelah Print Resep, anda tidak dapat merubah order, kecuali Super Admin. Lanjutkan?</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogConfirmPrintResep = false">No</v-btn>
              <v-btn color="blue darken-1" text @click="confirmPrint">Yes</v-btn>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
        </v-dialog>

    </div>
</template>

<script>
import moment from 'moment'
import LembarTimbang from '../components/LembarTimbang.vue'
import PrintBOM from '../components/PrintBOM.vue'
import PrintBOM2 from '../components/PrintBOM2.vue'
import PrintBOM3 from '../components/PrintBOM3.vue'
import PrintResep from '../components/PrintResep.vue'
export default {
    components: { LembarTimbang, PrintBOM, PrintBOM2, PrintBOM3, PrintResep },
    data: () => ({
      headers: [
        { text: 'No. Produksi', value: 'prod_no' },
        { text: 'Tanggal Produksi', value: 'production_date' },
        { text: 'Status Produksi', value: 'status' },
        { text: 'Lembar Timbang', value: 'lembar_timbang' },
        { text: 'Finished Goods', value: 'finished_goods' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      editDialog: false,
      materials: [],
      selectedProd: null,
      onProgress: false,
      production: null,
      isEditRequest: false,
      dialogFinish: false,
      selectedItem: null,
      selectedPrint: null,
      dialogConfirmPrintResep: false
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodStatus() {
        return this.miscCodes.filter(element => {
          return element.type=='Status Produksi'
        })
      },
      profile () {
        return this.$store.state.profile
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }
    },

    methods: {
      async initialize () {
        // console.log('initialized prod')
        this.onProgress = true
        this.items = (await this.$store.dispatch('getObjs', {apidomain:'others/productionorders', parameter:''})).data
        this.onProgress = false
      },

      getStatusName(code) {
        return (this.prodStatus.find(el => {return el.code==code})).description
      },


      async printBOM(obj) {
        const materials = (await this.$store.dispatch('getObjs', {apidomain:'others/productionmaterials/', parameter:obj.prod_no})).data
        // console.log(materials)
        materials.forEach(obj => {
          if (obj.berat_buah_kg)
            obj.qty_request = obj.berat_buah_kg
          else if (obj.uom=='kg'){
            obj.berat_buah_kg = (Number(obj.jml_resep_ml) / 1000).toFixed(2)
            obj.berat_daging_kg = obj.berat_buah_kg.toLocaleString()
          }
          else 
            obj.qty_request = obj.jml_resep_ml
        })

        this.$refs.printBom.openDialog(materials)
      },

      async getBOM(obj) {
        const materials = (await this.$store.dispatch('getObjs', {apidomain:'others/productionmaterialsperproduct/', parameter:obj.prod_no})).data
        materials.forEach(obj => {
          if (obj.berat_buah_kg){
            obj.qty_request = obj.berat_buah_kg
          }
            
          if (obj.uom_resep=='kg'){
            obj.berat_buah_kg = (Number(obj.jml_resep_ml) / 1000).toFixed(2)
            obj.berat_daging_kg = obj.berat_buah_kg
          }
          else {
            obj.qty_request = obj.jml_resep_ml
          }
        })
        return materials
      },

      async getBOM2(obj, category) {
        const materials = (await this.$store.dispatch('getObjs', {apidomain:'others/productionmaterialsperproduct2/' + obj.prod_no + '/', parameter: category})).data
        materials.forEach(obj => {
          // if (obj.category_code==category) {
            if (obj.berat_buah_kg)
              obj.qty_request = obj.berat_buah_kg
            else if (obj.uom=='kg'){
              obj.berat_buah_kg = (Number(obj.jml_resep_ml) / 1000).toFixed(2)
              obj.berat_daging_kg = obj.berat_buah_kg.toLocaleString()
            }
            else 
              obj.qty_request = obj.jml_resep_ml
          // }
          // else {
          //   obj.de
          // }
        })
        return materials
      },

      async printBOM2(obj) {
        const materials = await this.getBOM2(obj, 'RAW')
        this.$refs.printBom2.openDialog(materials)
      },

      async printBOM3(obj) {
        const bom = (await this.$store.dispatch('getObjs', {apidomain:'others/bottlesbom/', parameter: obj.prod_no})).data
        console.log(bom)
        this.$refs.printBom3.openDialog(bom)
      },

      async printResep(item) {
        this.selectedPrint = item
        this.dialogConfirmPrintResep = true
      },

      async confirmPrint() {
        this.dialogConfirmPrintResep = false
        const item = this.selectedPrint
        const prodVariants = (await this.$store.dispatch('getObjs', {apidomain:'others/orderproductionsum/', parameter:item.prod_no})).data
        const prodMaterials = (await this.$store.dispatch('getObjs', {apidomain:'others/materialsbyproduct/', parameter:item.prod_no})).data
        const uniqueProds = Object.values(
            prodVariants.reduce((acc, obj) => ({ ...acc, [obj.name]: obj }), {})
        );
        const bom = await this.getBOM(item)
        console.log(bom)

        const payload = []
        uniqueProds.forEach(prod => {
          const totalVol = this.getTotalVol(prod.name, bom)
          const batch = Math.ceil(totalVol/Number(prod.machine_capacity))
          const obj = {
            name: prod.name,
            date: item.production_date,
            batch: batch,
            total_vol: Math.round(totalVol/batch),
            bottles: [],
            materials: [],
            juices: []
          }
          prodVariants.forEach(variant => {
            if (prod.name == variant.name) {
              obj.bottles.push({
                size: variant.size,
                qty_request: variant.qty_request
              })
            }
          })
          prodMaterials.forEach(material => {
            if (prod.name == material.name) {
              obj.materials.push({
                material_code: material.material_code,
                material_name: material.description,
                qty: material.qty_request,
                volume_perc: material.volume_perc,
                uom_resep: material.uom_resep,
                rounding_resep: material.rounding_resep,
                jml_resep: this.getQty(material.material_code, prod.name, bom)/batch
              })
            }
          })
          bom.forEach(el => {
            if (prod.name == el.name && el.vj_code) {
              obj.juices.push({
                material_code: el.material_code,
                material_name: el.description,
                qty: el.qty_request,
                uom_resep: el.uom_resep,
                rounding_resep: el.rounding_resep,
                // jml_resep: Math.round(el.jml_resep/batch),
                jml_resep: el.jml_resep/batch,
                volume_perc: el.volume_perc,
                seed_percentage: el.seed_percentage,
                vj_code: el.vj_code,
                vj_name: el.vj_name
              })
            }
          });
          // sort array of object
          obj.juices.sort((a,b) => a.vj_code.localeCompare(b.vj_code) || a.material_code.localeCompare(b.material_code));
          // convert to master details
          const masterDetails = Object.values(obj.juices.reduce((acc, cur) => {
            if (!acc[cur.vj_code]) {
              acc[cur.vj_code] = { vj_code: cur.vj_code, vj_name: cur.vj_name, details: [] };
            }
            acc[cur.vj_code].details.push(cur);
            return acc;
          }, {}));
          payload.push(obj)
          obj.juices = masterDetails


        })
        console.log(payload)
        let order = {
          status: 1
        };
        await this.$store.dispatch('order/putObj', {apidomain:'orders?fields[prod_no]='+item.prod_no, payload:order})

        this.$refs.printResep.openDialog(payload)
        this.initialize()
      },
      
      getQty(material, variant, bom) {
          const item = bom.find(el => el.material_code == material && el.name == variant)
          console.log(item)
          if (item)
              return (item.jml_resep)
              // return (Math.round(item.jml_resep))
          else return 0
      },        
      getTotalVol(variant, bom) {
          const items = bom.filter(el => el.name == variant)
          let totalVol = 0
          if (items.length>0) {
            totalVol = items.reduce((acc, item) => {
              if (item.vj_code=='') {
                acc += (Math.round(item.jml_resep))
              }
              return acc
            }, 0)
          }

          return totalVol
      },        

      async inputLembarTimbang(item) {
        const bom = (await this.$store.dispatch('getObjs', {apidomain:'others/productionmaterials/', parameter:item.prod_no})).data
        let production = item
        production.details = bom
        console.log(production)
        this.$refs.refLembarTimbang.openDialog(production)
      },
      async editHasil(item) {
        // const production = await this.$store.dispatch('production/getObj', item.prod_no)
        // this.$refs.refLembarTimbang.openEditDialog(production)
        const bom = (await this.$store.dispatch('getObjs', {apidomain:'others/productionmaterialsedit/', parameter:item.prod_no})).data
        let production = item
        production.details = bom
        console.log(production)
        this.$refs.refLembarTimbang.openEditDialog(production)
      },

      finishedGoods(item) {
        this.$store.commit('production/setObj', item)
        this.$router.push('/inputhasilproduksi/'+item.prod_no)
      },

      finishProd(item) {
        this.selectedItem = item
        this.dialogFinish = true
      },

      cancelFinish() {
        this.dialogFinish = false
      },
      async confirmFinish() {
        this.onProgress = true
        let prodVariants = (await this.$store.dispatch('getObjs', {apidomain: 'productionVariants?fields[prod_no]=', parameter: this.selectedItem.prod_no})).data.data
        prodVariants.forEach(el => {
          el.qty_produced = Number(el.qty_produced_temp)
          // el.qty_produced_temp = 0
        });
        console.log(prodVariants)
        const payload = {variants:prodVariants}
        const docs = await this.$store.dispatch('production/postProductionVariants', payload)
        this.onProgress = false
        if (docs.status==200 || docs.status==201) {
          const prod = {
            prod_no: this.selectedItem.prod_no,
            status: 'FINISHPROD'
          }
          await this.$store.dispatch('production/putObj', prod)
          this.dialogFinish = false
          this.$store.commit('setSnackbar', {saveSuccess:true, successMessage:'Data berhasil tersimpan!'})
        }
        this.initialize()
        this.onProgress = false
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>