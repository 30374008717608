<template>
    <div>
        <!-- <v-dialog v-model="dialog" scrollable max-width="90%" :transition="false">
          <v-card> -->

        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="PrintResep"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <div v-for="(prod, idx) in printObj" :key="idx" id="printresep" class="pdf-item">
                    <table width="740px" style="margin: 30px 30px 5px 30px" class="pdf-item">
                    <!-- <table width="740px" style="margin-left:30px; margin-right:30px; margin-top:30px" class="pdf-item"> -->
                        <tr>
                            <td align="center" valign="middle">Tanggal:<br><b>{{prod.date | moment}}</b></td>
                            <td class="" style="vertical-align:middle">Pengawas: <div class="box" style="float:right"></div></td>
                            <td class="no-wrap">Mulai: <div class="box" style="float:right"></div></td>
                            <td class="no-wrap" colspan="2">Selesai: <div class="box" style="float:right"></div></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td align="center" valign="middle">Nama Produk:<br><b>{{prod.name}}</b></td>
                            <td class="" style="vertical-align:middle; text-align:center">Batch: <div class="box" style="float:right">{{prod.batch}}</div></td>
                            <td class="no-wrap" colspan="3" align="center"><div style="width:200px">Brix: <div class="box" style="float:right"></div></div></td>
                            <!-- <td class="no-wrap">Selesai: <div class="box" style="float:right"></div></td> -->
                        </tr>
                        <tr>
                            <td><b>Nama Bahan</b></td>
                            <td><b>Jumlah</b></td>
                            <td colspan="3">
                                <div v-for="(btl, i) in prod.bottles" :key="i" style="float:left; padding-right:10px">
                                    {{btl.size}} ml: {{btl.qty_request}} botol
                                </div>
                            </td>
                            <td><b>Cek</b></td>
                        </tr>
                        {{void(total=0)}}
                        <tr v-for="(mat, idx2) in prod.materials" :key="idx2" :set="val=mat.jml_resep">
                            <td>{{mat.material_name}}</td>
                            <td>{{convertAmount(mat)}} {{mat.uom_resep}}</td>
                            {{void(total=total+val)}}
                            <td colspan="3">&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{{round(total, -2)}} ml</td>
                            <td>Operator: <div class="box" style="float:right"></div></td>
                            <td colspan="3">Total Wadah: <div class="box" style="float:right"></div></td>
                        </tr>
                    </table>
                    <div v-for="(vj, i) in prod.juices" :key="i">
                        <table width="740px" style="margin-left:30px; margin-right:30px; margin-top:5px" class="pdf-item">
                            <tr>
                                <td colspan="4" style="font-weight:700">Untuk Blender {{vj.vj_name}}</td>
                            </tr>
                            <tr v-for="(dt, i2) in vj.details" :key="i2">
                                <td width="40%">{{dt.material_name}}</td>
                                <td width="20%">{{convertAmount(dt)}} {{dt.uom_resep}}</td>
                                <td width="20%">&nbsp;</td>
                                <td v-if="dt.seed_percentage">Biji {{round(convertAmount(dt) * (dt.seed_percentage/100), 2)}}</td>
                                <td v-else>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Hasil Aktual</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>
                    </div>
                    <!-- <div class="html2pdf__page-break"/> -->
                    <div v-if="(idx%2==1)" class="html2pdf__page-break"/>
                </div>

            </section>
        </vue-html2pdf>                
          <!-- </v-card>
        </v-dialog> -->
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'

export default {
    components: { VueHtml2pdf },

    data: () => ({
      dialog: false,
      onLoad: false,
      printObj: [],
    //   bom: null,
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('ddd D MMM YYYY');
        }
    },

    methods: {
        openDialog(obj) {
            this.dialog = true
            this.onLoad = true
            this.printObj = obj
            // obj.forEach(element => {
            //     if (element.name=='BERRY COLLAGEN') {
            //         console.log(element)
            //     }
            // });
            this.print()
        },
        convertAmount(obj) {
            let retAmount = obj.jml_resep
            if (obj.uom_resep == 'kg'){
                retAmount = obj.jml_resep / 1000
            } 
            console.log(obj)
            if (obj.material_code=='R-2120') {
              console.log(obj, retAmount)
            }

            return this.round(retAmount, (obj.rounding_resep||0))
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(ev) {
            this.onLoad = false
            console.log(ev)
        },
        round(value, decimals) {
            if (decimals>=0)
                return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals)
            else 
                return Math.round(value / Math.pow(10, Math.abs(decimals))) * Math.pow(10, Math.abs(decimals))
        },
        async print() {
            // await this.$htmlToPaper('printMe');
            // if (!(await this.validateControlValue())) return;
            this.$refs.html2Pdf.generatePdf()
        }
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #000000;
}
#printresep th, td {
  padding: 5px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
.pdf-item {
  page-break-inside: avoid;
  padding-top: 10px;
}
</style>