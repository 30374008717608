<template>
    <div>
        <v-dialog v-model="dialog" scrollable max-width="95%" :transition="false">
          <v-card>
            <v-card-text>

                <div id="printresep" >
                    <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">Bill of Material {{bom_number}}</div>
                    <table style="width:100%; margin: 0px;" class="pdf-item" ref="tableBOM">
                        <tr>
                            <td><b>Kode Bahan</b></td>
                            <td><b>Nama Bahan</b></td>
                            <td><b>Total Vol (ml)</b></td>
                            <td><b>% (kg)</b></td>
                            <td><b>% (ml)</b></td>
                            <td><b>Gross (kg)</b></td>
                            <td v-for="variant in variants" :key="variant"><b>{{variant}}</b></td>
                        </tr>
                        <tr v-for="(material, idx) in materials" :key="idx">
                            <template v-if="material.uom!='vj'">
                            <td>{{material.code}}</td>
                            <td>{{material.description2}}</td>
                            <td>{{getTotalVol(material.material_code)}}</td>
                            <td>{{material.volume_perc}}</td>
                            <td>{{material.volume_perc2}}</td>
                            <td>{{getGross(material.material_code)}}</td>
                            <td v-for="variant in variants" :key="variant.code">{{getQty(material.material_code, variant)}}</td>
                            </template>
                        </tr>
                    </table>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="grey" depressed @click="dialog=false">Close</v-btn>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
      dialog: false,
      onLoad: false,
      bom: null,
      materials: null,
      variants: null,
      bom_number: 2
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('ddd D MMM YYYY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }
    },

    methods: {
        async openDialog(items) {
            this.dialog = true
            this.bom = items
            this.onLoad = true
            this.materials = Object.values(
                items.reduce((acc, obj) => ({ ...acc, [obj.code]: obj }), {})
            );
            this.variants = (await this.$store.dispatch('variant/getObjs')).data.data
            this.variants = [...new Set(this.variants.map(item => item.name))]; 
            this.variants.sort()
            // this.materials = items
            console.log(this.bom)
            console.log(this.materials)

            this.onLoad = false
        },

        getQty(material, variant) {
            // console.log(this.bom)
            const item = this.bom.find(el => el.material_code == material && el.name == variant)
            // console.log(item)
            if (item)
            return (Math.round(item.jml_resep))
            else return 0
        },
        getTotalVol(material) {
            const items = this.bom.filter(el => el.material_code == material)
            if (items.length>0)
            return items.reduce((acc, item) => acc + (Math.round(item.jml_resep)), 0)
            else return 0
        },        
        getGross(material) {
            const items = this.bom.filter(el => el.material_code == material)
            if (items.length>0)
            return (items.reduce((acc, item) => acc + (Number(item.berat_buah_kg)), 0)).toFixed(2)
            else return 0

        },        
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(ev) {
            this.onLoad = false
            console.log(ev)
        },

        async print() {
            this.$refs.html2Pdf.generatePdf()
        },

        export2xls() {
            const wb = utils.table_to_book(this.$refs.tableBOM)
            writeFileXLSX(wb, "Export-FRM-004.xlsx")

        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 10px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
</style>