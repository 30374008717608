<template>
    <div>
        <v-dialog v-model="dialog" scrollable max-width="500px" :transition="false">
          <v-card>
            <v-card-text>

                <div id="printresep" >
                    <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">Bill of Material</div>
                    <table style="width:100%; margin: 0px;" class="pdf-item" ref="tableBOM">
                        <tr>
                            <td><b>Nama Bahan</b></td>
                            <td><b>Berat Buah (kg)</b></td>
                            <td><b>Berat Daging (kg)</b></td>
                            <td><b>Juice (ml)</b></td>
                        </tr>
                        <tr v-for="(prod, idx) in printObj" :key="idx">
                            <td>{{prod.description}}</td>
                            <td>{{prod.berat_buah_kg}}</td>
                            <td>{{prod.berat_daging_kg}}</td>
                            <td>{{Number(prod.jml_resep_ml).toFixed(2)}}</td>
                        </tr>
                    </table>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="grey" depressed @click="dialog=false">Close</v-btn>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
      dialog: false,
      onLoad: false,
      printObj: [],
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('ddd D MMM YYYY');
        },
        numbers: function (number) {
            return Number(number).toLocaleString()
        }
    },

    methods: {
        openDialog(obj) {
            this.dialog = true
            this.onLoad = true
            this.printObj = obj
            console.log(obj)
            this.onLoad = false
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(ev) {
            this.onLoad = false
            console.log(ev)
        },

        async print() {
            this.$refs.html2Pdf.generatePdf()
        },

        export2xls() {
            const wb = utils.table_to_book(this.$refs.tableBOM)
            writeFileXLSX(wb, "BOM.xlsx")

        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 10px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
</style>